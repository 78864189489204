<template>
  <v-row>
    <v-col
      cols="12"
      md="3"
    >
      <v-autocomplete
        v-model="budget.client_id"
        :items="clients"
        :disabled="!disabledFields"
        item-value="id"
        :item-text="
          (item) => (item.trading_name ? item.trading_name : item.name)
        "
        label="Cliente*"
        append-outer-icon="mdi-eye"
        @click:append-outer="() => goToClient(budget.client_id)"
        rounded
        :rules="[(v) => !!v || 'Campo obrigatório']"
        outlined
        dense
      />
      <InfoList
        headerTitle="Cliente"
        :info="clientItems"
        :values="selectedClient"
      />
    </v-col>
    <v-col
      cols="12"
      md="3"
    >
      <v-select
        v-model="budget.client_contact_id"
        :items="contacts"
        item-text="name"
        item-value="id"
        label="Contato*"
        :disabled="!budget.client_id || !disabledFields"
        :rules="[(v) => !!v || 'Campo obrigatório']"
        rounded
        outlined
        dense
      />
      <InfoList
        headerTitle="Contato"
        :info="contactItems"
        :values="selectedContact"
      />
    </v-col>
    <v-col
      cols="12"
      md="3"
    >
      <v-autocomplete
        :disabled="!disabledFields"
        v-model="budget.seller_id"
        :items="sellers"
        item-text="name"
        item-value="id"
        label="Vendedor*"
        rounded
        outlined
        dense
      />
      <InfoList
        headerTitle="Vendedor"
        :info="sellerItems"
        :values="selectedSeller"
      />
    </v-col>
    <v-col
      cols="12"
      md="3"
    >
      <v-select
        v-model="budget.company_id"
        :items="companies"
        item-text="company_name"
        item-value="id"
        :disabled="!disabledFields"
        label="Empresa*"
        :rules="[(v) => !!v || 'Campo obrigatório']"
        rounded
        outlined
        dense
      />
      <InfoList
        headerTitle="Empresa"
        :info="companyItems"
        :values="selectedCompany"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {
    InfoList: () => import('./MainDataInfoList.vue'),
  },
  props: {
    budget: { type: Object, default: () => {} },
    clients: { type: Array, default: () => [] },
    sellers: { type: Array, default: () => [] },
    companies: { type: Array, default: () => [] },
    disabledFields: Boolean,
  },
  data: () => ({
    sellers: [],
    companies: [],
    contacts: [],
    clientItems: [
      { label: 'Nome: ', value: 'name' },
      { label: 'Razão Social: ', value: 'company_name' },
      { label: 'Nome Fantasia: ', value: 'trading_name' },
      { label: 'CPF / CNPJ: ', value: 'cpf_cnpj' },
    ],
    contactItems: [
      { label: 'Contato: ' },
      { label: 'Email: ' },
      { label: 'Telefone: ' },
    ],
    sellerItems: [
      { label: 'Nome: ' },
      { label: 'Email: ' },
      { label: 'Telefone: ' },
    ],
    companyItems: [
      { label: 'Nome: ' },
      { label: 'Email: ' },
      { label: 'Telefone: ' },
      { label: 'CNPJ: ' },
      { label: 'Estado: ' },
      { label: 'Cidade: ' },
      { label: 'Endereço: ' },
    ],
  }),
  watch: {
    selectedClient(newvalue) {
      this.budget.seller_id = newvalue[5]
    },
    'budget.client_id': {
      handler() {
        this.getContactsByClientId()
      },
      immediate: true,
    },
  },
  computed: {
    selectedClient() {
      let foundClient = this.clients.find(
        (el) => el.id == this.budget.client_id
      )
      if (foundClient) {
        let {
          name,
          company_name,
          trading_name,
          cpf_cnpj,
          client_contact,
          seller_id,
        } = foundClient
        return [
          name,
          company_name,
          trading_name,
          cpf_cnpj,
          client_contact,
          seller_id,
        ]
      }
      return []
    },
    selectedContact() {
      if (this.budget.client_contact_id) {
        let { name, email, phone_number } = this.contacts[0]
        return [name, email, phone_number]
      }
      return []
    },
    selectedSeller() {
      let foundSeller = this.sellers.find(
        (el) => el.id == this.budget.seller_id
      )
      if (foundSeller) {
        let { name, email, contact } = foundSeller
        return [name, email, contact]
      }
      return []
    },
    selectedCompany() {
      let foundCompany = this.companies.find(
        (el) => el.id == this.budget.company_id
      )
      if (foundCompany) {
        let { company_name, email, phone_number, cnpj, uf, city, address } =
          foundCompany
        return [company_name, email, phone_number, cnpj, uf, city, address]
      }
      return []
    },
  },
  methods: {
    goToClient(id) {
      const routeData = this.$router.resolve({ path: '/people/clients/' + id })
      window.open(routeData.href, '_blank')
    },
    getContactsByClientId() {
      if (this.budget.client_id) {
        this.$api
          .post('/budget/contacts_by_client', { id: this.budget.client_id })
          .then((res) => {
            this.contacts = res.data.data
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
  },
}
</script>
